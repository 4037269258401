// JoinWaitlistButton.js
import React from "react";
import { useNavigate } from "react-router-dom";

function JoinWaitlistButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/onboarding");
  };

  return (
    <button
      onClick={handleClick}
      className="bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform-gpu hover:bg-subtle-blue-dark hover:text-blue-950 hover:scale-110 hover:rotate-6 hover:skew-x-6 hover:shadow-2xl hover:-translate-y-1 hover:translate-x-1 mt-4"
    >
      Join Waitlist
    </button>
  );
}

export default JoinWaitlistButton;
