import JoinWaitlistButton from "./JoinWaitlistButton";
import DraggableIcons from "./DraggableIcons";
import Cards from "./Cards";
import FullWidthCard from "./FullWidthCard";
import IrregularShapesSection from "./IrregularShapesSection";
import Footer from "./Footer";
import Carousel from "./Carousel";
import HorizontalCard from "./HorizontalCard";
import Globe from "../components/Globe";
import { createGlobe } from "../utils/createGlobe";
import globeConfig from "../config/globeConfig";

function Home() {
  return (
    <div className="justify-between mt-10">
      <DraggableIcons />
      {/* <h1 className="text-5xl font-extrabold font-poppins text-center">
              Discover Collaborate Create
            </h1> */}
      <div>
        <p className="text-center font-poppins text=lg text-bold mt-0 p-16">
          Experience seamless connections and creativity with Braccet. <br></br>
          Our platform unites brands and creators to produce viral content
          <br></br>that captivates and engages audiences.
        </p>
        <div className="flex justify-center mt-10">
          <JoinWaitlistButton />
        </div>
      </div>

      <div>
        <Carousel />
      </div>

      <div className="min-h-screen  flex items-center justify-center mt-32">
        <Cards />
      </div>
      <div>
        <h1 className="text-center font-poppins text-3xl font-bold mt-16">
          The Best is in the Process ⌛
        </h1>
        <FullWidthCard />
      </div>

      <div className="mt-32">
        <IrregularShapesSection />
      </div>

      <section className="w-full cream py-12 md:py-20 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4">
              Explore the World with Braccet
            </h2>
            <p className="text-lg mb-8">
              Discover a new way of creative collaboration.
            </p>
          </div>

          {/* Globe component positioned within this section */}
          <div className="mt-8 globe-wrapper cream">
            <Globe />
          </div>
        </div>
      </section>

      <div>
        <HorizontalCard />
      </div>

      <div className="text-center mt-32">
        <p className="text-xl mb-6">🔶 Thoughts</p>
        <h1 className="text-3xl font-bold mb-4">Join With Us Today</h1>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
