import React from "react";

// Import your icons
import perfect_match from "../Assets/images/perfect_match.png";
import Vision from "../Assets/images/Vision.png";
import Collaborate from "../Assets/images/Collaborate.png";
import Review from "../Assets/images/Review.png";
import Celebrate from "../Assets/images/Celebrate.png";
import Icon6 from "../Assets/images/icon6.png";

function IrregularShapesSection() {
  const shapeData = [
    {
      title: "Perfect Match",
      description:
        "Brands, meet your dream team of content creators. Creators, find your ideal brand partners.",
      icon: perfect_match,
      shapeClass: "shape-1",
    },
    {
      title: "Vision",
      description:
        "Once you've found your match, it's time to bring your vision to life.",
      icon: Vision,
      shapeClass: "shape-2",
    },
    {
      title: "Collaborate Seamlessly",
      description: "Say goodbye to endless emails and missed deadlines.",
      icon: Collaborate,
      shapeClass: "shape-3",
    },
    {
      title: "Review & Revise",
      description:
        "real-time insights into performance metrics, audience engagement, and more.",
      icon: Review,
      shapeClass: "shape-4",
    },
    {
      title: "Celebrate Success",
      description: "Celebrate every milestone, big or small.",
      icon: Celebrate,
      shapeClass: "shape-5",
    },
    {
      title: "Voila!",
      description:
        "Its That easy to Collaborate a create content that skyrokets you brand.",
      icon: Icon6,
      shapeClass: "shape-6",
    },
  ];

  return (
    <div className="container mx-auto p-8">
      <div className="text-center mb-8">
        <p className="text-xl mb-6">🔶 Discover</p>
        <h1 className="text-3xl font-bold mb-4">Our Unique Features</h1>
        <p className="text-lg mb-24">
          Learn more about what makes our platform unique and innovative.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-8">
        {shapeData.map((shape, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className={`irregular-shape bg-gray-900 flex items-center justify-center text-4xl mb-4 ${shape.shapeClass}`}
            >
              <img src={shape.icon} alt={shape.title} className="w-12 h-12" />
            </div>
            <h2 className="text-2xl font-bold mt-4">{shape.title}</h2>
            <p className="text-lg text-center">{shape.description}</p>
          </div>
        ))}
      </div>

      <style jsx>{`
        .irregular-shape {
          width: 100px;
          height: 100px;
          transition: transform 0.2s;
        }

        .shape-1 {
          border-radius: 50% 25% 50% 25%;
        }

        .shape-2 {
          border-radius: 40% 60% 30% 70%;
        }

        .shape-3 {
          border-radius: 55% 45% 55% 45%;
        }

        .shape-4 {
          border-radius: 60% 40% 50% 50%;
        }

        .shape-5 {
          border-radius: 45% 55% 60% 40%;
        }

        .shape-6 {
          border-radius: 35% 65% 40% 60%;
        }

        @keyframes jiggle {
          0%,
          100% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(2deg);
          }
          50% {
            transform: rotate(-2deg);
          }
          75% {
            transform: rotate(2deg);
          }
        }

        .irregular-shape:hover {
          animation: jiggle 0.5s ease-in-out;
        }
      `}</style>
    </div>
  );
}

export default IrregularShapesSection;
