import createGlobeLibrary from "cobe"; // Ensure the correct path to the cobe library

export function createGlobe(canvas, config) {
  if (!canvas) {
    console.error("Canvas element is null or undefined.");
    throw new Error("Canvas element is required.");
  }

  if (!config) {
    console.error("Configuration object is null or undefined.");
    throw new Error("Configuration object is required.");
  }

  try {
    // Initialize the globe using the imported library or custom logic
    const globe = createGlobeLibrary(canvas, config);

    // Additional setup or configurations can go here

    return globe; // Return the initialized globe object
  } catch (error) {
    console.error("Failed to create the globe:", error);
    throw error; // Re-throw the error after logging it
  }
}
