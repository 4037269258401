import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Body from "./components/Body";
import Creator from "./components/Creator";
import Brand from "./components/Brand";
import OnboardingPage from "./components/OnboardingPage"; // Ensure this import
import BrandOnBoardingPage from "./components/BrandOnBoardingPage"; // Placeholder for actual component
import CreatorOnBoarding from "./components/CreatorOnBoarding"; // Placeholder for actual component
import ComingSoonPage from "./components/ComingSoonPage";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Body />,
    },
    {
      path: "/Creator",
      element: <Creator />,
    },
    {
      path: "/Brand",
      element: <Brand />,
    },
    {
      path: "/onboarding",
      element: <OnboardingPage />,
    },
    {
      path: "/BrandOnBoardingPage",
      element: <BrandOnBoardingPage />,
    },
    {
      path: "/CreatorOnBoardingPage",
      element: <CreatorOnBoarding />,
    },
    {
      path: "/ComingSoonPage",
      element: <ComingSoonPage />,
    },
  ]);

  return (
    <div style={{ backgroundColor: "#fffaf5", minHeight: "auto" }}>
      <RouterProvider router={appRouter}>
        <Body />
      </RouterProvider>
    </div>
  );
}

export default App;
