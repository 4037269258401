import React, { useEffect, useRef } from "react";
import { useSpring } from "react-spring";
import { GLOBE_CONFIG } from "../config/globeConfig"; // Correct path to the config
import { createGlobe } from "../utils/createGlobe"; // Correct path to the utility function
import "./globe.css"; // Import the CSS file for Globe component styles

const Globe = () => {
  let phi = 0;
  let width = 0;
  const canvasRef = useRef(null);
  const pointerInteracting = useRef(null);
  const pointerInteractionMovement = useRef(0);
  const [{ r }, api] = useSpring(() => ({
    r: 0,
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }));

  const updatePointerInteraction = (value) => {
    pointerInteracting.current = value;
    canvasRef.current.style.cursor = value ? "grabbing" : "grab";
  };

  const updateMovement = (clientX) => {
    if (pointerInteracting.current !== null) {
      const delta = clientX - pointerInteracting.current;
      pointerInteractionMovement.current = delta;
      api.start({ r: delta / 200 });
    }
  };

  const onRender = (state) => {
    if (!pointerInteracting.current) phi += 0.005;
    state.phi = phi + r.get();
    state.width = width * 2;
    state.height = width * 2;
  };

  const onResize = () => {
    if (canvasRef.current) {
      width = canvasRef.current.offsetWidth;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    try {
      const globe = createGlobe(canvasRef.current, {
        ...GLOBE_CONFIG,
        width: width * 2,
        height: width * 2,
        onRender,
      });

      setTimeout(() => (canvasRef.current.style.opacity = "1"));
      return () => globe.destroy();
    } catch (error) {
      console.error("Error initializing the globe:", error);
    }
  }, []);

  return (
    <div className="globe-container">
      <canvas
        className="h-full w-full opacity-0 transition-opacity duration-500"
        ref={canvasRef}
        onPointerDown={(e) =>
          updatePointerInteraction(
            e.clientX - pointerInteractionMovement.current
          )
        }
        onPointerUp={() => updatePointerInteraction(null)}
        onPointerOut={() => updatePointerInteraction(null)}
        onMouseMove={(e) => updateMovement(e.clientX)}
        onTouchMove={(e) =>
          e.touches[0] && updateMovement(e.touches[0].clientX)
        }
      />
    </div>
  );
};

export default Globe;
