import React from "react";
import Nav from "./Nav";
import Home from "./Home";

function Body() {
  return (
    <div className="">
      <Nav />
      <Home />
    </div>
  );
}

export default Body;
