import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faLinkedin,
  faYoutubeSquare,
  faSnapchatSquare,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

const socialMediaIcons = [
  { icon: faFacebookSquare, name: "Facebook", color: "#3b5998" },
  { icon: faTwitterSquare, name: "Twitter", color: "#1DA1F2" },
  { icon: faInstagramSquare, name: "Instagram", color: "#C13584" },
  { icon: faLinkedin, name: "LinkedIn", color: "#0e76a8" },
  { icon: faYoutubeSquare, name: "YouTube", color: "#c4302b" },
  { icon: faSnapchatSquare, name: "Snapchat", color: "#FFFC00" },
  { icon: faTiktok, name: "TikTok", color: "#010101" },
];

function Carousel() {
  return (
    <div className=" min-h-screen flex flex-col items-center justify-center -mb-72 -mt-24">
      <div className="text-center mb-8">
        <p className="text-xl mb-6">🤝 Connect</p>
        <h1 className="text-3xl font-bold mb-4">
          Connect anywhere - everywhere
        </h1>
      </div>
      <div className="relative overflow-hidden w-full max-w-4xl mx-auto">
        <div className="absolute inset-0 z-10 pointer-events-none">
          <div className="absolute left-0 w-24 h-full bg-gradient-to-r bgcream to-transparent"></div>
          <div className="absolute right-0 w-24 h-full bg-gradient-to-l bgcream to-transparent"></div>
        </div>
        <div className="flex space-x-8 animate-scroll">
          {Array(2)
            .fill("")
            .map((_, index) =>
              socialMediaIcons.map((item, i) => (
                <div
                  key={i + index * socialMediaIcons.length}
                  className="min-w-[100px] flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="text-4xl"
                    style={{ color: item.color }}
                  />
                </div>
              ))
            )}
        </div>
      </div>
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        .animate-scroll {
          animation: scroll 20s linear infinite;
        }
      `}</style>
    </div>
  );
}

export default Carousel;
