import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/images/braccet-logo.png"; // Adjust path as needed

function OnboardingPage() {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardSelect = (card) => {
    setSelectedCard(card);
  };

  const handleContinue = () => {
    if (selectedCard === "card1") {
      navigate("/BrandOnBoardingPage");
    } else if (selectedCard === "card2") {
      navigate("/CreatorOnBoardingPage");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bgcream">
      <div className="flex items-center mb-8">
        <img src={Logo} alt="Logo" className="rounded-full h-12 w-12 mr-4" />
        <h1 className="text-3xl font-bold">Braccet</h1>
      </div>
      <h2 className="text-3xl font-bold mb-4 text-center">
        Welcome to Our Onboarding
      </h2>
      <p className="text-lg mb-8 text-center">
        Get started by choosing an option below
      </p>
      <div className="flex  mb-8 flex-wrap justify-center sm:justify-start">
        <div
          onClick={() => handleCardSelect("card1")}
          className={`p-4 border rounded-lg cursor-pointer w-[200px] mb-6 mx-4 ${
            selectedCard === "card1"
              ? "bg-blue-500 text-white border-blue-500"
              : "bg-white text-black border-gray-300"
          }`}
        >
          <div className="flex items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            <h3 className="text-lg font-bold">Brand</h3>
          </div>
          <p>Get Started as a brand</p>
        </div>
        <div
          onClick={() => handleCardSelect("card2")}
          className={`p-4 border rounded-lg cursor-pointer w-[200px] mb-6 ${
            selectedCard === "card2"
              ? "bg-blue-500 text-white border-blue-500"
              : "bg-white text-black border-gray-300"
          }`}
        >
          <div className="flex items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <h3 className="text-lg font-bold">Creator</h3>
          </div>
          <p>Get Started as a creator</p>
        </div>
      </div>
      <button
        onClick={handleContinue}
        disabled={!selectedCard}
        className={`bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform-gpu hover:bg-subtle-blue-dark hover:text-blue-950 hover:scale-110 hover:shadow-2xl ${
          !selectedCard && "opacity-50 cursor-not-allowed"
        }`}
      >
        Continue
      </button>
    </div>
  );
}

export default OnboardingPage;
