// src/components/Footer.js

import React from "react";
import JoinWaitlistButton from "./JoinWaitlistButton";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import "./Footer.css";

function Footer() {
  return (
    <footer className=" py-16 px-10 md:px-32">
      <div className="footer-main container mx-auto bg-gray-900 rounded-3xl p-8 md:p-12 shadow-lg">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
          {/* Left Section */}
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold mb-4 text-white">
              Join the early access now.
            </h2>
            <p className="text-lg text-white mb-4">
              Braccet will be launching very shortly, and we would like to
              onboard you to be among the first to try it out.
            </p>
            {/* Join Waitlist Button */}
            <div className="self-start">
              <JoinWaitlistButton />
            </div>
          </div>

          {/* Right Section */}
          <div className="w-full md:w-1/2 flex flex-col md:flex-row items-start md:items-center justify-end md:space-x-4">
            {/* Social Icons */}
            <div className="flex space-x-4">
              <a href="#" className="text-white">
                <FaFacebook className="text-2xl hover:text-blue-600" />
              </a>
              <a href="#" className="text-white">
                <FaTwitter className="text-2xl hover:text-blue-400" />
              </a>
              <a href="#" className="text-white">
                <FaInstagram className="text-2xl hover:text-pink-500" />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="relative overflow-visible w-full mt-8">
          <p className="relative right-full transform translate-x-full translate-y-1/2 text-white text-center font-bold text-5xl md:text-9xl mb-5">
            Braccet
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
