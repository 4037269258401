// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAJN9PE_ErH3s-3k2ktrGDoquhy-xrwByE",
  authDomain: "braccet.firebaseapp.com",
  projectId: "braccet",
  storageBucket: "braccet.appspot.com",
  messagingSenderId: "1054283081313",
  appId: "G-7CX75Y56YL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { auth, provider, db };
