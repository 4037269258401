// src/components/FullWidthCard.js

import React from "react";
import JoinWaitlistButton from "./JoinWaitlistButton";

function FullWidthCard() {
  return (
    <div className="container mx-auto py-8 ">
      <div className="bg-blue-50 font-poppins justify-items-center border-2 border-blue-900 rounded-3xl p-16 mx-4 md:mx-16 lg:mx-32 xl:mx-64">
        <h2 className="text-2xl font-bold text-center ">
          <span className="underline"> BRACCET</span> is Lauching very soon, and
          we cant wait for you to try it.
        </h2>
        <div className="flex justify-center mt-8">
          <JoinWaitlistButton />
        </div>
      </div>
    </div>
  );
}

export default FullWidthCard;
