import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, provider, db } from "../firebaseConfig";
import { signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import Logo from "../Assets/images/braccet-logo.png"; // Adjust path as needed
import googleLogo from "../Assets/images/google-logo.png"; // Add this image in your assets
import "./BrandOnboardingPage.css"; // Import CSS for this component

const CreatorOnBoardingPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email + "creator",
        "defaultPassword" // You can use a default password or generate one
      );
      const user = userCredential.user;

      // Save user data in Firestore
      await setDoc(doc(db, "brands", user.uid), {
        email,
        source: "brandPage",
      });
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Save user data in Firestore
      await setDoc(doc(db, "brands", user.uid), {
        email: user.email,
        source: "brandPage",
      });

      navigate("/ComingSoonPage");
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bgcream p-8">
      <div className="absolute top-4 left-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-white text-gray-700 rounded-full p-2 shadow-md transition duration-300 ease-in-out transform-gpu hover:bg-gray-200 hover:scale-110"
        >
          <i className="fas fa-arrow-left text-2xl"></i>
        </button>
      </div>
      <div className="flex items-center mb-8">
        <img src={Logo} alt="Logo" className="rounded-full h-16 w-16 mr-4" />
        <h1 className="text-4xl font-bold">Braccet</h1>
      </div>
      <div>
        <h3 className="font-poppins mb-3">Signing-in as Creator</h3>
      </div>
      <div className="max-w-xl w-full bg-white p-8 rounded-lg shadow-lg relative">
        {/* Signup Form */}
        <form
          className="space-x-4"
          onSubmit={() => {
            handleEmailSubmit();
            navigate("/ComingSoonPage");
          }}
        >
          <div className="flex flex-col items-start space-y-4">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  className="block w-full p-3 border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 pl-10"
                  placeholder="you@example.com"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <i className="far fa-envelope text-gray-400"></i>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign Up
            </button>
          </div>
        </form>
        {/* Google Sign In */}
        <div className="mt-6 flex items-center justify-center">
          {/* <button
            onClick={handleGoogleSignIn}
            className="flex items-center space-x-2 py-2 px-4 bg-white border border-gray-300 rounded-md shadow-sm transition duration-300 ease-in-out transform-gpu hover:bg-gray-100 hover:scale-105"
          >
            <img src={googleLogo} alt="Google Logo" className="w-6 h-6" />
            <span className="text-sm font-medium text-gray-700">
              Sign in with Google
            </span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default CreatorOnBoardingPage;
