import React, { useState, useEffect } from "react";
import "./DraggableIcons.css"; // Import your CSS file for styles

function DraggableIcons() {
  const initialIconPositions = {
    icon1: { x: -500, y: -350 }, // Adjust these values for the initial position of icon1
    icon2: { x: 300, y: -100 }, // Adjust these values for the initial position of icon2
    icon3: { x: -500, y: 100 }, // Adjust these values for the initial position of icon3
    icon4: { x: 400, y: 250 }, // Adjust these values for the initial position of icon4
  };

  const [iconPositions, setIconPositions] = useState(initialIconPositions);
  const [initialPositions, setInitialPositions] = useState({});
  const [draggingIcon, setDraggingIcon] = useState(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!draggingIcon || !initialPositions[draggingIcon]) return;

      const { clientX, clientY } = event;
      const deltaX = clientX - initialPositions[draggingIcon].x;
      const deltaY = clientY - initialPositions[draggingIcon].y;

      setIconPositions((prevPositions) => ({
        ...prevPositions,
        [draggingIcon]: {
          x: prevPositions[draggingIcon].x + deltaX,
          y: prevPositions[draggingIcon].y + deltaY,
        },
      }));

      setInitialPositions((prevPositions) => ({
        ...prevPositions,
        [draggingIcon]: { x: clientX, y: clientY },
      }));
    };

    const handleMouseUp = () => {
      if (draggingIcon) {
        setIconPositions((prevPositions) => ({
          ...prevPositions,
          [draggingIcon]: initialIconPositions[draggingIcon],
        }));
        setDraggingIcon(null);
        setInitialPositions({});
      }

      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    if (draggingIcon) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggingIcon, initialPositions, initialIconPositions]);

  const handleMouseDown = (event, icon) => {
    event.preventDefault(); // Prevent text selection
    const { clientX, clientY } = event;
    setInitialPositions((prevPositions) => ({
      ...prevPositions,
      [icon]: { x: clientX, y: clientY },
    }));
    setDraggingIcon(icon);
  };

  return (
    <div>
      <h1 className="main-text text-8xl font-bold text-center mt-16">
        Discover Collaborate
        <span className="text-blue-700 font-mono">
          <br></br>(
        </span>
        <span>Create</span>
        <span className="text-blue-700 font-mono">)</span>
      </h1>
      <div className="icons-container icon-main">
        <div
          className="icon"
          style={{
            transform: `translate(${iconPositions.icon1.x}px, ${iconPositions.icon1.y}px)`,
          }}
          onMouseDown={(e) => handleMouseDown(e, "icon1")}
        >
          🎬
        </div>
        <div
          className="icon"
          style={{
            transform: `translate(${iconPositions.icon2.x}px, ${iconPositions.icon2.y}px)`,
          }}
          onMouseDown={(e) => handleMouseDown(e, "icon2")}
        >
          🎉
        </div>
        <div
          className="icon"
          style={{
            transform: `translate(${iconPositions.icon3.x}px, ${iconPositions.icon3.y}px)`,
          }}
          onMouseDown={(e) => handleMouseDown(e, "icon3")}
        >
          📽️
        </div>
        <div
          className="icon"
          style={{
            transform: `translate(${iconPositions.icon4.x}px, ${iconPositions.icon4.y}px)`,
          }}
          onMouseDown={(e) => handleMouseDown(e, "icon4")}
        >
          🤝
        </div>
      </div>
    </div>
  );
}

export default DraggableIcons;
