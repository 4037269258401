import React from "react";

function Creator() {
  return (
    <div className="">
      <h1 className="">This is Creator Page</h1>
      <p className="">
        lorem3slfjdflkgkjadfgklasfjgjkedhgkjhglkjhglkjfhkajhfaskjfskh
      </p>
    </div>
  );
}

export default Creator;
