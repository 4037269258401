import React from "react";
import KamranRazviPhoto from "../Assets/images/kamran-razvi.jpg"; // Replace with your actual photo path

function HorizontalCard() {
  return (
    <div className="mt-20 max-w-5xl mx-auto bg-white border border-blue-800 rounded-3xl shadow-lg overflow-hidden">
      <div className="flex flex-col items-center md:flex-row justify-between px-16 py-6 border-b border-blue-800">
        {/* Content section */}
        <div className="mb-6 md:mb-0 text-center md:text-left">
          <h2 className="text-3xl font-bold text-black mb-2">
            Book a 1 on 1 call
          </h2>
          <p className="text-lg text-black mb-4">
            Wanna chat about your specific use case? <br />
            Let's jump on a 15-minute call and help you win!
          </p>
          <a
            href="https://calendly.com/kamranrazvi/braccet-1-on-1-call"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-full shadow-lg transform-gpu transition duration-300 ease-in-out hover:bg-subtle-blue-dark hover:text-blue-950 hover:scale-110 hover:rotate-6 hover:skew-x-6 hover:shadow-2xl hover:-translate-y-1 hover:translate-x-1"
          >
            Book Now
          </a>
        </div>

        {/* Photo and name section */}
        <div className="flex flex-col items-center md:items-start md:text-left mt-6 md:mt-0">
          <img
            src={KamranRazviPhoto}
            alt="Kamran Razvi"
            className="rounded-full h-16 w-16 object-cover mb-2 md:mb-0"
          />
          <div>
            <p className="text-lg font-semibold text-black">Kamran Razvi</p>
            <p className="text-sm text-gray-500">Founder at Braccet</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalCard;
