import React, { useEffect, useRef } from "react";
import "./Cards.css"; // Import your separate CSS file for animations

// Import images
import discoveryImage from "../Assets/images/discovery.png";
import workgroupImage from "../Assets/images/workgroup.png";
import addimage from "../Assets/images/add.png";

function Cards() {
  const cardRefs = {
    card1: useRef(null),
    card2: useRef(null),
    card3: useRef(null),
  };

  useEffect(() => {
    const cards = Object.values(cardRefs);

    const handleScroll = () => {
      cards.forEach((card) => {
        if (card.current && isInViewport(card.current)) {
          card.current.classList.add("animate-card");
        }
      });
    };

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on initial load

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="min-h-screen">
      <div className="container mx-auto p-8">
        <div className="text-center mb-8">
          <p className="text-xl mb-6">🤝 Discover</p>
          <h1 className="text-3xl font-bold mb-2">What Braccet Offers</h1>
          <p className="text-lg mb-16">
            Perks so good you'll never need to go anywhere else for your
            content.
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 px-4 md:px-20">
          {/* Card 1 */}
          <div
            ref={cardRefs.card1}
            className="card-container relative overflow-hidden bg-blue-900 rounded-3xl shadow-lg p-6 transform transition duration-500 hover:scale-105"
          >
            <div className="flex items-center justify-start mb-4">
              <img
                src={discoveryImage}
                alt="Custom Icon 1"
                className="h-16 w-16 mb-2 mr-4"
              />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-white">Discover</h2>
            <p className="text-lg text-white">
              Check out Braccet to find cool brands and creators to connect
              with.
            </p>
          </div>

          {/* Card 2 */}
          <div
            ref={cardRefs.card2}
            className="card-container relative overflow-hidden bg-gray-900 rounded-3xl shadow-lg p-6 transform transition duration-500 hover:scale-105"
          >
            <div className="flex items-center justify-start mb-4">
              <img
                src={workgroupImage}
                alt="Custom Icon 2"
                className="h-16 w-16 mb-6 mr-4"
              />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-white">Collaborate</h2>
            <p className="text-lg text-white">
              Work together and make viral content that everyone will talk
              about.
            </p>
          </div>

          {/* Card 3 */}
          <div
            ref={cardRefs.card3}
            className="card-container relative overflow-hidden bg-gray-900 rounded-3xl shadow-lg p-6 transform transition duration-500 hover:scale-105"
          >
            <div className="flex items-center justify-start mb-4">
              <img
                src={addimage}
                alt="Custom Icon 3"
                className="h-16 w-16 mb-2 mr-4"
              />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-white">Create</h2>
            <p className="text-lg text-white">
              Make your brand pop with unique campaigns and creative projects.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
