import React from "react";
import Logo from "../Assets/images/braccet-logo.png";

const ComingSoonPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex items-center mb-8">
        <img src={Logo} alt="Logo" className="rounded-full h-16 w-16 mr-4" />
        <h1 className="text-4xl font-bold">Braccet</h1>
      </div>
      <h2 className="text-3xl font-bold mb-4">We are launching soon!</h2>
      <p className="text-lg text-center">
        Thanks for signing up! Look out for a surprise in your mailbox very
        soon.
      </p>
    </div>
  );
};

export default ComingSoonPage;
