import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Assets/images/braccet-logo.png";

function Nav() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleNavClick = (path) => {
    setActiveLink(path);
  };

  return (
    <header>
      <div className="w-48 m-auto p-5">
        <div className="flex justify-center items-center mb-2">
          <img src={logo} className="rounded-full w-10 mx-4"></img>
          <h1 className="font-poppins text-3xl font-semibold">Braccet</h1>
        </div>
        {/* <ul className="flex font-bold text-white bg-blue-400 rounded-full justify-center">
          <Link to="/Creator" onClick={() => handleNavClick("/Creator")}>
            <li
              className={`p-2 m-2 rounded-full transition-transform duration-200 ${
                activeLink === "/Creator" ? "bg-blue-500" : ""
              } hover:bg-blue-500 hover:scale-105 text-sm`}
            >
              Creator
            </li>
          </Link>
          <Link to="/Brand" onClick={() => handleNavClick("/Brand")}>
            <li
              className={`p-2 m-2 rounded-full transition-transform duration-200 ${
                activeLink === "/Brand" ? "bg-blue-500" : ""
              } hover:bg-blue-500 hover:scale-105 text-sm`}
            >
              Brand
            </li>
          </Link>
        </ul> */}
      </div>
    </header>
  );
}

export default Nav;
